// useAuth.js
import { useState, useEffect } from 'react';
import { auth } from '../../firebase'; // Adjust the path as needed

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return { isLoggedIn, setIsLoggedIn };
}

export default useAuth;
