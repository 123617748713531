import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getStorage } from "firebase/storage"; // Import for Firebase Storage
import { getFirestore } from 'firebase/firestore'; // Import for Firestore


const firebaseConfig = {
    apiKey: "AIzaSyC6SN3wx14hEHVi0hLK-KlqrpevClJ0Vhs",
    authDomain: "veryfied-a17fa.firebaseapp.com",
    projectId: "veryfied-a17fa",
    storageBucket: "veryfied-a17fa.appspot.com",
    messagingSenderId: "739900084043",
    appId: "1:739900084043:web:8bfef7ab1ef568ca52a2ce",
    measurementId: "G-DLPP45R4MC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize storage
const db = getFirestore(app); // Initialize Firestore

export { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, db, storage };
