import React, { useState, useEffect } from 'react';

function TasksPopup({ onClose }) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const popupBoxStyle = {
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999
    };

    const boxStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity
        width: isSmallScreen ? '90%' : '600px', // 90% screen width or max 600px
        height: '70vh', // 70% screen height
        borderRadius: '5px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
    };

    const containerStyle = (height, width = '100%') => ({
        height: `${height}px`,
        backgroundColor: 'black', // Black background
        width: width, // Dynamic width
        border: '1px solid white' // Added border
    });

    const flexContainerStyle = {
        display: 'flex',
        height: '100%'
    };

    const buttonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '24px',
        width: '29%',
        backgroundColor: 'black', // Black background
        border: '1px solid white',
        color: 'white',
        fontSize: '20px',
        cursor: 'pointer'
    };

    const popupContentStyle = {
        ...containerStyle(70),
        color: 'white',
        textAlign: 'center',
        flex: 1 // Fill remaining space
    };

    return (
        <div style={popupBoxStyle}>
            <div style={boxStyle}>
                <div style={containerStyle(30)}>
                    <div style={flexContainerStyle}>
                        <div style={containerStyle('100%', '85%')}></div>
                        <button style={buttonStyle} >Tab1 </button>
                        <button style={buttonStyle} > Tab1 </button>
                        <button style={buttonStyle} >Tab1 </button>
                        <div style={containerStyle(30, '15%')}>
                            <div style={flexContainerStyle}>
                                <button style={buttonStyle} onClick={onClose}>+</button>
                                <button style={buttonStyle} onClick={onClose}>▢</button>
                                <button style={buttonStyle} onClick={onClose}>✖</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={containerStyle(40)}></div>
                <div style={popupContentStyle}>
                    <h1>Hello, you are in tasks</h1>
                </div>
                <div style={containerStyle(25)}></div>
            </div>
        </div>
    );
}

export default TasksPopup;
