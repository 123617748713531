import React, { useState } from 'react';
import { createUserWithEmailAndPassword, auth, signInWithEmailAndPassword, updateProfile } from './firebase';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc } from 'firebase/firestore'; // For Firestore
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import getDownloadURL here

function Register() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState(''); // New state for image URL
  const [instagramLink, setInstagramLink] = useState('');
  const [facebookLink, setFacebookLink] = useState('');
  const navigate = useNavigate();
  const db = getFirestore();
  

const storage = getStorage();


const [profilePicPreview, setProfilePicPreview] = useState(null);

const handleProfilePictureUrlChange = (e) => {
  setProfilePictureUrl(e.target.value);
};

 
const handleSubmit = async (e) => {
  e.preventDefault();
  if (password !== confirmPassword) {
    alert('Passwords do not match. Please try again.');
    return;
  }
  const dummyEmail = `${username}@veryfied.me`; // Use dummy email for Firebase Authentication

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, dummyEmail, password);

    // Update the user's profile with the username as displayName
    await updateProfile(userCredential.user, {
      displayName: username,
    });

    // Immediately sign in the user
    await signInWithEmailAndPassword(auth, dummyEmail, password);

    // Store user's additional information in Firestore
    const userId = userCredential.user.uid; // Get current user's UID
    let profilePicUrl = profilePictureUrl || ''; // Simplified this line

    await setDoc(doc(db, 'users', userId), {
      firstName: firstName,
      lastName: lastName,
      username: username,
      realEmail: email,
      phoneNumber: phoneNumber,
      profilePicture: profilePicUrl,
      instagramLink: instagramLink,
      facebookLink: facebookLink,
      userName: username,
      verified: 0,
      role: 0
    });

    console.log('User created successfully');
    alert('Success! You have a new user.');
    navigate('/'); // Navigate to homepage or dashboard as needed
  } catch (error) {
    console.error(error);
    alert('Registration failed. Please try again later.');
  }
};


  

  return (
    <div>
      <h1>Register</h1>
      <form onSubmit={handleSubmit}>
      {profilePicPreview && (
            <img src={profilePicPreview} alt="Profile Preview" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
          )}
                 <div></div>
                 <div>
                    <input
                        type="url"
                        value={profilePictureUrl}
                        onChange={handleProfilePictureUrlChange}
                        placeholder="Profile Picture URL (Optional)"
                    />
                </div>
                {profilePicPreview || profilePictureUrl ? (
                    <img 
                        src={profilePicPreview || profilePictureUrl} 
                        alt="Profile Preview" 
                        style={{ width: '100px', height: '100px', borderRadius: '50%' }} 
                    />
                ) : null}
        <div></div>
        <div>

        
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <div></div>
        <input
    type="text"
    value={firstName}
    onChange={(e) => setFirstName(e.target.value)}
    placeholder="First Name"
  />
</div>
<div>
  <input
    type="text"
    value={lastName}
    onChange={(e) => setLastName(e.target.value)}
    placeholder="Last Name"
  />
</div>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email Address"
        />
        
        <div></div>
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
        />
        
        <div></div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        
        <div></div>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
        />
        

        
        <div></div>

        <input
          type="text"
          value={instagramLink}
          onChange={(e) => setInstagramLink(e.target.value)}
          placeholder="Instagram Link (Optional)"
        />
        
        <div></div>
        <input
          type="text"
          value={facebookLink}
          onChange={(e) => setFacebookLink(e.target.value)}
          placeholder="Facebook Link (Optional)"
        />
        <div></div>
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default Register;
